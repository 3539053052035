import { Link } from "react-router-dom";
import MapView from "../components/Map";
import "../styles/Principal.css";
import Autoplay from "../components/CarouselComponent";
import FooterComponent from "../components/FooterComponent";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import axios from "axios";

// IMAGES


import car_icon_2 from "../img/car-icon-2.webp";
import car_icon_3 from "../img/car-icon-3.webp";
import car_icon_4 from "../img/car-icon-4.webp";
import car_icon_6 from "../img/car-icon-6.webp";
import check from "../img/Check.webp";
import wspicon from "../img/icon-wsp-chat.webp";
import mapaicon from "../img/mapa.webp";

function PrincipalPage(){
    
    const [dataJson, setDataJson] = useState([]);

     const loadData = async() => {
        const result = await axios("data/data.json");
        if (result.data.length === 0 ){
            const data = require("../data/data.json");
            setDataJson(data);
        }else {
            setDataJson(result.data);
        }        
    }

    useEffect(() => {
        loadData();
    },[]);

    function enviarEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_aa73ld9','template_butavvh',e.target,'Qbm7JUiygFthG0Qe_')
            .then(res=>{
                Swal.fire({
                    icon: 'success',
                    title: 'Enviado',
                    text: 'El correo fue enviado',
                })
        })
    }

    
    return(dataJson.length === 0 ? (<div className = "loader"></div>) :
        (<div>
            <Autoplay/> 
            <section className = "section-nosotros" id = "nosotros">    
                    <div className = "container-nosotros">
                        <div className = "nosotros-datos">
                            <h2 className = "nosotros-title">Nosotros</h2> 
                            <p className = "nosotros-text">
                               {dataJson.nosotros.contenido}
                            </p>                           
                        </div>
                        <div className = "nosotros-caracteristicas">
                            <div className = "nosotros-caracteristica">
                                <img className = "caracteristica-img" src = {check} alt = "personal.jpeg"/>
                                <div className = "caracteristica-detalle">
                                    <span className = "detalle-span">{dataJson.nosotros.caracteristicas[0].titulo}</span>
                                    <p>{dataJson.nosotros.caracteristicas[0].descripcion}</p>
                                </div>
                            </div>
                            <div className = "nosotros-caracteristica">
                                <img className = "caracteristica-img" src = {check} alt = "direccion.png" />
                                <div className = "caracteristica-detalle">
                                    <span className = "detalle-span">{dataJson.nosotros.caracteristicas[1].titulo}</span>
                                    <p>{dataJson.nosotros.caracteristicas[1].descripcion}</p>
                                </div>
                            </div>
                            <div className = "nosotros-caracteristica">
                                <img className = "caracteristica-img" src = {check} alt = "horario.png" />
                                <div className = "caracteristica-detalle">
                                    <span className = "detalle-span">{dataJson.nosotros.caracteristicas[2].titulo}</span>
                                    <p>
                                        <table className = "horario-table">
                                            <tbody>
                                                {dataJson.nosotros.caracteristicas[2].horarios.map(horario => (
                                                    <tr key={horario.id}> 
                                                        <td className = "columna-dias">{horario.dia} : </td>
                                                        <td className = "columna-hora">{horario.hora}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 
            </section>
            <div className = "container-map" >
                <MapView />
            </div>
            <section className = "section-servicios" id = "servicios">
                <h1 className = "servicios-title">Servicios</h1>
                <div className = "container-servicios">
                    <div className = "servicio">
                        <img src = {car_icon_2} alt = "particular.png"/>
                        <div className = "servicio-datos">
                            <span className = "servicio-span">Vehiculos Particulares</span>
                            <ul className = "servicio-text">
                                <li>Tarjeta de propiedad</li>
                                <li>SOAT vigente</li>
                                <li>Revisión técnica anterior (no requerido si es la primera inspección)</li>
                                <li>Certificado anual vigente de GLP/GNV (si el vehiculo usa este combustible)</li>
                            </ul>
                        </div>
                    </div>
                    <div className = "servicio">
                        <img src = {car_icon_3} alt = "taxi.png"/>
                        <div className = "servicio-datos">
                            <span className = "servicio-span">Servicio de Taxi</span>
                            <ul className = "servicio-text">
                                <li>Tarjeta de propiedad</li>
                                <li>SOAT o Afocat vigente</li>
                                <li>Revisión técnica anterior (no requerido si es la primera inspección)</li>
                                <li>Certificado anual vigente de GLP/GNV (si el vehiculo usa este combustible)</li>
                                <li>Tarjeta Única de Circulación (TUC) o permiso para el servicio de taxi.</li>
                            </ul>
                        </div>
                    </div>
                    <div className = "servicio">
                        <img src = {car_icon_4} alt = "mercancia.png"/>
                        <div className = "servicio-datos">
                            <span className = "servicio-span">Transporte de Mercancías en General</span>
                            <ul className = "servicio-text">
                                <li>Tarjeta de propiedad</li>
                                <li>SOAT vigente</li>
                                <li>Revisión técnica anterior (no requerido si es la primera inspección)</li>
                                <li>Tarjeta Única de Circulación (TUC) o permiso para el servicio de transporte de mercancías.</li>
                            </ul>
                        </div>
                    </div>
                    <div className = "servicio">
                        <img src = {car_icon_6} alt = "mercanciapeligrosa.png"/>
                        <div className = "servicio-datos">
                            <span className = "servicio-span">Transporte de Mercancías Peligrosas</span>
                            <ul className = "servicio-text">
                                <li>Tarjeta de propiedad</li>
                                <li>SOAT o Afocat vigente</li>
                                <li>Revisión técnica anterior (no requerido si es la primera inspección)</li>
                                <li>Tarjeta Única de Circulación (TUC) o permiso para el servicio de transporte de mercancías peligrosas.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className = "section-tarifas" id = "tarifas">
                <h2 className = "tarifas-title">Tarifas</h2>
                <table className = "table">
                    <thead>
                        <tr>
                            <th className = "cabecera-table">VEHÍCULOS</th>
                            <th className = "cabecera-table">TARIFA DESDE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataJson.tarifas.map(tarifa => (
                            <tr key ={tarifa.id}>
                                <td className = "vehiculo-columna">{tarifa.vehiculo}</td>
                                <td className = "precio-columna">{tarifa.precio}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <section className = "section-contactenos">
                <div className = "container-contactanos">
                    <form onSubmit={enviarEmail} className= "contactanos-formulario">
                        <h2 className = "contactanos-title" id = "contactanos">Contáctanos</h2>
                        <p className = "contactanos-text">Bríndenos sus datos y nos pondremos en contacto con Ud.</p>
                        <div className = "contactanos-formulario-inputs">
                            <div className = "formulario-input">
                                <label className = "span-input">Nombre</label>
                                <input id = "nombre" name = "nombre" className = "input-item" required type = "text"></input>
                            </div>
                            <div className = "formulario-input">
                                <label className = "span-input">E-mail</label>
                                <input id = "email" name = "email" className = "input-item" required type = "email"></input>
                            </div>
                            <div className = "formulario-input">
                                <label className = "span-input">Teléfono</label>
                                <input id = "telefono" name = "telefono" className = "input-item" required type = "text"></input>
                            </div>
                            <div className = "formulario-input">
                                <label className = "span-input">Mensaje</label>
                                <textarea style={{"height":"100px"}} id = "mensaje" name = "mensaje" className = "input-item" required type = "text"></textarea>
                            </div>
                        </div>
                        <button className = "btn-enviar" type = "submit">Enviar</button>
                    </form>
                </div>
            </section>
            <Link className = "btn-map" target = "_blank" to = "https://www.google.com/maps/place/Consorcio+Saneamiento+Cajamarquilla/@-11.9824673,-76.9494859,17z/data=!3m1!4b1!4m6!3m5!1s0x9105c4f18b7ff2ef:0xdb3ac452f0bae780!8m2!3d-11.9824726!4d-76.9472972!16s%2Fg%2F11c1z996fx"><img src = {mapaicon} alt = "mapa.webp"/></Link>
            <Link className = "btn-wsp" target = "_blank" to = "https://api.whatsapp.com/send?phone=903001997"><img src = {wspicon} alt = "wsp.webp" /></Link>
            <FooterComponent/>
        </div>)
    );
}

export default PrincipalPage;