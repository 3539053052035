import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet"
import "leaflet/dist/leaflet.css";
import {IconLocation} from "../components/MarkerMap";
import "../styles/marker.css"

function MapView(){

    const position = [-11.982325651176373, -76.94720064143905]


    return <MapContainer center = {position} zoom = {17} touchZoom = "center">
        <TileLayer 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={position} icon={IconLocation}>
            <Popup>
                Centro de Inspección Técnica Vehicular JP S.A.C
            </Popup>
        </Marker>
    </MapContainer>
}

export default MapView;